//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-328:_496,_4010,_6184,_8628,_1780,_5080,_8000,_3184;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-328')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-328', "_496,_4010,_6184,_8628,_1780,_5080,_8000,_3184");
        }
      }catch (ex) {
        console.error(ex);
      }