import shopifySparkGenericDefaults from '../shopify-spark-generic/config.js';

export default {
  includes: ['shopify-spark-generic'],
  ...shopifySparkGenericDefaults,
  SearchRequestDefaults: {
    ...shopifySparkGenericDefaults.SearchRequestDefaults,
  },
  fitmentSearch: {
    ...shopifySparkGenericDefaults.fitmentSearch,
    isAutoRedirectDisabled: true,
  },
  Widgets: [
    ...shopifySparkGenericDefaults.Widgets.filter((w) => !['SearchHeader'].includes(w.name)),
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm-home',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 730,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: ['Year', 'Make', 'Model'],
      },
    },
  ],
};
